<template>
    <v-navigation-drawer
        :location="appLang == 'ar' ? 'left' : 'right'"
        :direction="appLang == 'ar' ? 'left' : 'right'"
        v-model="internalDrawer"
        :color="getDrawerColor(theme)"
    >
        <!-- :theme="theme" -->
        <!-- <v-list-item
            title="KhalMag"
            :prepend-avatar="require('@/assets/imgs/logo/KM.png')"
        >
        </v-list-item> -->

        <!-- :prepend-avatar="require('@/assets/imgs/logo/KM.png')" -->
        <v-list-item
            to="/my-profile"
            :title="profileData.name"
            :subtitle="profileData.email"
            :prepend-avatar="profileData.avatar"
        >
            <!-- <img :src="require(`${profileData.avatar}`)" /> -->
        </v-list-item>

        <v-list density="compact" nav width="100%" class="px-1">
            <!-- Permissions Start -->

            <v-list-group
                prepend-icon="mdi-security"
                value=""
                class="px-0"
                style="justify-items: center"
            >
                <template v-slot:activator="{ props }">
                    <v-list-item
                        style="justify-items: center"
                        v-bind="props"
                        :title="$t('permissions')"
                    ></v-list-item>
                </template>

                <v-list-item
                    v-for="([title, icon, to], i) in permissions"
                    :key="i"
                    :title="title"
                    :prepend-icon="icon"
                    :value="title"
                    :to="to"
                ></v-list-item>
            </v-list-group>
            <!-- Permissions End -->

            <!-- Theme Start -->
            <v-list-group
                prepend-icon="mdi-palette-swatch-variant"
                value="themeStyle"
                class="px-0"
                style="justify-items: center"
            >
                <template v-slot:activator="{ props }">
                    <v-list-item
                        style="justify-items: center"
                        v-bind="props"
                        :title="$t('themes')"
                    ></v-list-item>
                </template>

                <!-- <v-list-item
                    v-for="([title, icon, to], i) in themeStyle"
                    :key="i"
                    :title="title"
                    :prepend-icon="icon"
                    :value="title"
                    :to="to"
                    @click="openThemList(i)"
                ></v-list-item> -->

                <v-select
                    variant="outlined"
                    width="100%"
                    density="compact"
                    :label="$t('select')"
                    v-model="selectedTheme"
                    :items="myThemes"
                    @update:modelValue="setTheme()"
                    :theme="theme"
                    label-position="bottom"
                ></v-select>
            </v-list-group>
            <!-- Theme End -->
        </v-list>
        <v-main style="height: 250px"></v-main>
    </v-navigation-drawer>
</template>
<script>
import store from "@/store";
export default {
    name: "SettingsSidebar",

    props: {
        appLang: {
            type: String,
        },
        drawer: {
            type: Boolean,
        },
    },
    data() {
        return {
            internalDrawer: this.drawer,
            themList: false,
            myThemes: store.getters.getAppThemesList,
            selectedTheme: store.getters.getAppTheme,
            theme: store.getters.getAppTheme,
            permissions: [
                [this.$t("groups"), "mdi-account-group-outline", "/group-list"],
                ["Settings", "mdi-cog-outline", "/"],
            ],
            themeStyle: [[this.$t("themes"), "mdi mdi-shape-outline", ""]],
        };
    },
    computed: {
        profileData() {
            return this.$store.getters.getUserData;
        },
        requireAvatar() {
            return require(`${this.profileData.avatar}`);
        },
    },
    // mounted() {
    //     store.dispatch("getMyProfile");
    // },
    watch: {
        drawer(newVal) {
            this.internalDrawer = newVal;
        },
        "$store.getters.getAppTheme"(newTheme) {
            this.theme = newTheme;
            // console.log(newTheme, "from this :", this.theme);
        },
    },
    methods: {
        openThemList(index) {
            if (index === 0) {
                this.themList = !this.themList;
            }
        },
        setTheme() {
            store.commit("SET_THEME", this.selectedTheme);
            // theme.global.name.value = store.getters.getAppTheme;
            // console.log("app.vue theme", this.selectedTheme);
        },
        getDrawerColor(theme) {
            switch (theme) {
                case "myCustomLightTheme":
                    return "primary";
                case "light":
                    return "primary";
                case "dark":
                    return "black";
                case "customDarkTheme":
                    return "#15202b"; // or any color you want for customDarkTheme
                default:
                    return "#15202b";
            }
        },
        getSelectBackgroundColor() {
            switch (this.theme) {
                case "myCustomLightTheme":
                case "light":
                    return "#FFFFFF"; // Set the background color for light themes
                case "dark":
                    return "black"; // Set the background color for dark themes
                case "customDarkTheme":
                    return "#15202b"; // Set the background color for custom dark themes
                default:
                    return "#dddd"; // Default background color
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.them-list-container {
    position: absolute;
    right: 200px;
    top: 100px;
    background-color: gray;
    z-index: 999999;
    width: 30vw;
}
.label-bottom .v-label {
    & label {
        transform: translateY(-50%);
        transform-origin: center;
        position: fixed;
        right: 24px;
        top: 0px;
        margin: 0 4px;
        z-index: 9999;
        background: #1867c0;
        padding: 5px;
    }
}
</style>
