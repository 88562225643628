<template>
    <v-container
        fluid
        class="p-0 m-0 login-page-container"
        style="min-height: 100vh"
        :style="lang == 'en' ? 'direction:ltr' : 'direction:rtl'"
    >
        <LoadingComponent :isLoading="isLoading" />
        <VRow
            v-if="intro"
            align="center"
            justify="center"
            style="height: 100vh"
            class="p-0 m-0 login-intro w-100"
        >
            <VCol col="12">
                <img
                    class="intro-logo rounded-circle"
                    style="
                        position: absolute;
                        top: 20%;
                        left: 39%;
                        height: 20vw;
                        width: 20%;
                    "
                    src="../../assets/imgs/logo/KM.png"
                    alt=""
                />
                <!-- <h1
                    col="12"
                    class="text-center"
                    style="
                        font-size: 5rem;
                        position: absolute;
                        top: 60vh;
                        left: 34%;
                        height: 30vw;
                        width: 30%;
                    "
                >
                    {{ $t("welcome") }} !
                </h1> -->

                <img
                    style="
                        position: absolute;
                        bottom: 0;
                        left: 34%;
                        height: 30vw;
                        width: 30%;
                    "
                    src="../../assets/imgs/login/welcome©no-bg.gif"
                    alt=""
                />
            </VCol>
        </VRow>

        <VRow
            v-else
            align="center"
            justify="center"
            style="height: 100vh"
            class="p-0 m-0"
        >
            <VCol
                cols="4"
                height="100%"
                class="d-md-flex d-lg-flex d-sm-none d-none"
                :class="
                    lang == 'en'
                        ? 'login-image-container-en'
                        : 'login-image-container-ar'
                "
            >
            </VCol>
            <VCol
                v-if="!intro"
                transition="slide-fade"
                cols="12"
                xs="12"
                sm="12"
                md="8"
                lg="8"
                xl="8"
            >
                <v-sheet class="mx-auto" theme="light">
                    <v-img
                        class="d-block mx-auto mb-6"
                        width="300"
                        :src="
                            require('../../assets/imgs/logo/f-gardens-logo-2.png')
                        "
                    ></v-img>
                    <!-- <h4 class="my-3 text-center text-success">
                        {{ $t("loginToYourAccount") }}
                    </h4> -->
                    <!-- validate-on="validateForm" -->
                    <v-form
                        ref="form"
                        style="width: 60%"
                        fast-fail
                        @submit.prevent
                        class="mx-auto"
                    >
                        <!-- direction="horizontal" -->
                        <v-text-field
                            :hint="$t('loginWith')"
                            clearable
                            :prepend-icon="
                                lang == 'en' ? 'mdi-account-tie' : ''
                            "
                            :append-icon="lang == 'ar' ? 'mdi-account-tie' : ''"
                            v-model="formData.identifier"
                            :rules="userNameRules"
                            :label="$t('user')"
                            :placeholder="$t('loginWith')"
                        ></v-text-field>
                        <!--  density="compact" -->
                        <!-- variant="outlined" -->
                        <!-- counter -->
                        <v-text-field
                            :max-errors="3"
                            clearable
                            :rules="passwordRules"
                            :label="$t('password')"
                            :append-inner-icon="
                                visible ? 'mdi-eye-off' : 'mdi-eye'
                            "
                            v-model="formData.password"
                            :type="visible ? 'text' : 'password'"
                            :placeholder="$t('password')"
                            @click:append-inner="visible = !visible"
                            :prepend-icon="
                                lang == 'en' ? 'mdi-lock-outline' : ''
                            "
                            :append-icon="
                                lang == 'ar' ? 'mdi-lock-outline' : ''
                            "
                        ></v-text-field>

                        <v-checkbox
                            label="remember me"
                            v-model="rememberUser"
                            @change="rememberUserFun()"
                        ></v-checkbox>

                        <!-- login btn -->
                        <v-btn
                            @click="validateForm"
                            :loading="loading"
                            block
                            class="mt-2"
                            theme="light"
                        >
                            {{ $t("login") }}</v-btn
                        >
                    </v-form>
                    <!-- <div class="text-danger m-0 p-0">{{ errorMsg }}</div> -->

                    <v-snackbar
                        class="mb-10"
                        v-model="snackbar"
                        multi-line
                        color="red"
                    >
                        <div class="text-subtitle-1 pb-2">
                            {{ errorMsg }}
                        </div>

                        <!-- <p>{{ errorMsg }}</p> -->

                        <template v-slot:actions>
                            <v-btn
                                color="indigo"
                                variant="text"
                                @click="snackbar = false"
                            >
                                <v-icon color="white">mdi mdi-close</v-icon>
                            </v-btn>
                        </template>
                    </v-snackbar>
                </v-sheet>
            </VCol>
        </VRow>
    </v-container>
</template>

<script>
// import { validate } from "vee-validate";
import store from "@/store";
import LoadingComponent from "@/components/animations/LoadingComponent.vue";
import { mapGetters } from "vuex";
export default {
    name: "LoginComponent",
    components: {
        LoadingComponent,
    },
    mounted() {
        // this.intro = true;
        setTimeout(() => {
            this.intro = false;
        }, 3500);
    },
    data() {
        return {
            isLoading: false,
            snackbar: false,
            lang: localStorage.getItem("lang"),
            intro: false,
            loading: false,
            userNameRules: [
                (value) => {
                    if (value?.length > 3) return true;
                    return this.$t("loginValidate.usernameValidate");
                },
            ],
            passwordRules: [
                (v) =>
                    !!v ||
                    // "Password is required",
                    this.$t("loginValidate.passwordValidateRequired"),
                (v) =>
                    (v && v.length >= 8) ||
                    // "Password must be at least 8 characters",
                    this.$t("loginValidate.passwordValidate8chars"),
                // (v) =>
                //     /[A-Z]/.test(v) ||
                //     // "Password must contain at least 1 uppercase character",
                //     this.$t("loginValidate.passwordValidateUpperChars"),
                (v) =>
                    /[a-z]/.test(v) ||
                    // "Password must contain at least 1 lowercase character",
                    this.$t("loginValidate.passwordValidateLowerChars"),
                (v) =>
                    /[0-9]/.test(v) ||
                    // "Password must contain at least 1 number",
                    this.$t("loginValidate.passwordValidateNumber"),
            ],
            timeout: null,
            formData: {
                identifier: "",
                password: "",
            },
            errorMsg: "",
            rememberUser: localStorage.getItem("rememberUser") || false,

            // password input
            visible: false,
            password: "Password",
            // rules: {
            //     required: (value) => !!value || "Required.",
            //     min: (v) => v.length >= 8 || "Min 8 characters",
            //     emailMatch: () =>
            //         `The email and password you entered don't match`,
            // },
        };
    },
    computed: {
        ...mapGetters(["getIsLogin"]),
    },
    methods: {
        async validateForm() {
            // Explicitly call validate method on the form
            await this.$refs.form.validate();

            // Check if there are any validation errors
            const errors = Array.from(this.$refs.form.errors);
            if (errors.length === 0) {
                this.login(); // only call login if valid
            } else {
                // console.log("Form is not valid");
            }
        },
        async login() {
            this.loading = true;
            this.isLoading = true;
            try {
                const response = await this.$store.dispatch(
                    "login",
                    this.formData
                );
                if (response.status === 200) {
                    // "this updated" - Login successful, handle accordingly
                    this.loading = false;
                    this.isLoading = false;
                    this.snackbar = true;
                    this.$router.push("/");
                } else {
                    // "this updated" - Login failed, handle error message
                    this.errorMsg = response.response.data.detail;
                    this.snackbar = true;
                }
            } catch (error) {
                // "this updated" - Handle login error
                this.errorMsg = "عفوا تعذر الإتصال  !";
                this.snackbar = true;
            } finally {
                // "this updated" - Ensure loading is set to false regardless of the outcome
                this.loading = false;
                this.isLoading = false;
            }
        },
        rememberUserFun() {
            if (this.rememberUser == true) {
                store.commit("SET_REMEMBER_USER", true);
            } else {
                localStorage.removeItem("rememberUser");
            }
        },
    },
};
</script>
<style lang="scss" scoped>
// .login-page-container {
//     // animation: loginFade 2s ease-in-out;
// }
.login-intro {
    position: fixed;
    // background: #ffc107;
    // background: rgb(255, 255, 255);
    // background: linear-gradient(
    //     77deg,
    //     rgba(255, 255, 255, 1) 1%,
    //     rgba(255, 193, 7, 1) 47%
    // );
    background: #54d4ff;
    background: rgb(255, 255, 255);
    background: linear-gradient(
        77deg,
        rgba(255, 255, 255, 1) 1%,
        rgb(79, 188, 246) 47%
    );
    & h1 {
        text-align: center;
        color: white;
        animation: loginIntro 3s ease-in-out;
    }
}
.intro-logo {
    animation: loginFade 2s ease-in-out;
}
.login-image-container-en {
    height: 100%;
    padding: 0;
    background-image: url("../../assets/imgs/login/1.png");
    background-size: cover;
    background-size: 100% 100%;
    background-position: center;
}
.login-image-container-ar {
    height: 100%;
    padding: 0;
    background-image: url("../../assets/imgs/login/2.png");
    background-size: cover;
    background-size: 100% 100%;
    background-position: center;
}
@keyframes loginFade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes loginIntro {
    0% {
        opacity: 0.5;
        color: black;
    }
    50% {
        color: rgb(255, 225, 0);
    }
    25%,
    75% {
        color: rgb(251, 128, 128);
    }
    100% {
        opacity: 1;
        color: white;
    }
}
</style>
