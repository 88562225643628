const storeLang = localStorage.getItem("lang") || "en";
export const LANG = storeLang;
export const ApiUsers = "/api/users";
export const ApiPermission = "/api/permissions";

export const ApiProjectSetup = "/api/project_setup";
export const ApiService = "/api/service";
export const ApiCounter = "/api/counter";
export const ApiCategory = "/api/category";
export const ApiProducts = "/api/product";
export const ApiProject = "/api/project";
export const ApiAboutUs = "/api/about_us";
export const ApiPartner = "/api/partner";
export const ApiContactUs = "/api/contact_us";
// ==========================================================================
// ============================== Project setup ================================
// =============================**************===============================
//#region Project setup
// Project setup Start
export const THEME_LIST = `${LANG}${ApiProjectSetup}/theme_list/`; // theme list
export const CREATE_THEME = `${LANG}${ApiProjectSetup}/theme_create/`; // create theme
// Project setup End
//#endregion

// ============================== USER Module ================================
// =============================**************===============================
//#region //user Module
// user Module
export const LOGIN = `${LANG}${ApiUsers}/login/`; // login
export const CREATE_USER = `${LANG}${ApiUsers}/create_user/`; // create_user
export const GET_ALL_USERS = `${LANG}${ApiUsers}/user_list/`; // GET All Users
export const GET_USERS_DIALOG = `${LANG}${ApiUsers}/user_dialog/`; // UserDialog
export const GET_USERS_DELETED = `${LANG}${ApiUsers}/user_deleted_list/`; // user_deleted_list
export const GET_USER_ME = `${LANG}${ApiUsers}/me/`; // Me GET & PATCH Methods
export const USER_RETRIEVE = `${LANG}${ApiUsers}/user_retrieve/`; // user_retrieve
export const USER_UPDATE_MY_MANAGER = `${LANG}${ApiUsers}/user_update/`; // /user_update/
export const USER_TEMP_DELETE = `${LANG}${ApiUsers}/user_temp_delete/`; // /user_temp_delete/
export const USER_DELETE_PERMANENTLY = `${LANG}${ApiUsers}/user_delete/`; // user_delete Permanently
export const USER_RESTORE = `${LANG}${ApiUsers}/user_restore/`; // user_restore
// export const GET_USER_ROLE_DIALOG = `${LANG}${ApiUsers}/user_role_dialog/`; //  GET Dialog role
// export const GET_USER_ROLE_DIALOG = `${LANG}${ApiUsers}/user_role_dialog/`; //  GET Dialog role
export const CHANGE_MY_PASSWORD = `${LANG}/auth/users/set_password/`; //  users change password
export const CHANGE_MY_PHOTO = `${LANG}${ApiUsers}/upload_photo/`; //  /upload_photo/
export const CHANGE_MY_COVER_PHOTO = `${LANG}${ApiUsers}/upload_cover/`; //  /upload_cover/
export const USER_ROLE_DIALOG = `${LANG}${ApiUsers}/user_role_dialog/`; //  user_role_dialog
//#endregion //user Module

// ============================== GroupsAndPermissions Module ================================
// =============================**************===============================
//#region // Start Group Module
export const GROUP_CREATE = `${LANG}${ApiPermission}/group_create/`; // GroupCreate
export const GROUP_LIST = `${LANG}${ApiPermission}/group_list/`; // GroupList
export const GROUP_DIALOG = `${LANG}${ApiPermission}/group_dialog/`; // GroupDialog
export const GROUP_ADD_USER = `${LANG}${ApiPermission}/assign_user_to_group/`; // AssignUserToGroup
export const GROUP_REMOVE_USER = `${LANG}${ApiPermission}/remove_user_from_group/`; // RemoveUserFromGroup
export const GROUP_DELETE_PERMANENTLY = `${LANG}${ApiPermission}/group_delete/`; // GroupDelete
// Permissions
export const PERMISSIONS_DIALOG = `${LANG}${ApiPermission}/permissions_dialog/`; // PermissionDialog
export const PERMISSIONS_ASSIGN_TO_USER = `${LANG}${ApiPermission}/assign_permissions_to_user/`; // AssignPermissionsToUser
export const PERMISSIONS_REMOVE_FROM_USER = `${LANG}${ApiPermission}/remove_permissions_from_user/`; // RemovePermissionsFromUser
export const PERMISSIONS_REMOVE_FROM_GROUP = `${LANG}${ApiPermission}/remove_permissions_from_group/`; // RemovePermissionsFromGroup
//#endregion // End Group Module

// ============================== ** Module ================================
// =============================**************===============================

// ============================== SERVICES Module ================================
// =============================**************===============================
//#region // Start SERVICES Module
export const ALL_SERVICES = `${LANG}${ApiService}/service_list/`; // ALL_SERVICES GET
export const SERVICE_CREATE = `${LANG}${ApiService}/service_create/`; // ServiceCreate
export const SERVICE_TEMP_DELETE = `${LANG}${ApiService}/service_temp_delete/`; // /ServiceTempDelete
export const CHANGE_SERVICE_STATUS = `${LANG}${ApiService}/change_service_status/`; // ServiceChangeStatus
export const SERVICE_DELETED_LIST = `${LANG}${ApiService}/deleted_service_list/`; // DeletedServices
export const SERVICE_RESTORE = `${LANG}${ApiService}/service_restore/`; // service_restore/
export const SERVICE_RETRIEVE = `${LANG}${ApiService}/service_retrieve/`; // service_retrieve
export const SERVICE_UPDATE = `${LANG}${ApiService}/service_update/`; // ServiceUpdate
export const SERVICE_DELETE_PERMANENTLY = `${LANG}${ApiService}/service_delete/`; // ServiceDelete
export const SERVICE_DIALOG = `${LANG}${ApiService}/service_dialog/`; // ServiceDialog
export const SERVICE_IMAGE_DELETE = `${LANG}${ApiService}/service_image_delete/`; // ServiceImageDelete

//#endregion // End SERVICES Module

// ============================== ** Module ================================
// =============================**************===============================

// ============================== Counter Module ================================
// =============================**************===============================
//#region // Start SERVICES Module
export const ALL_COUNTERS = `${LANG}${ApiCounter}/service_list/`; // All_Counters GET
export const COUNTER_CREATE = `${LANG}${ApiCounter}/counter_create/`; // CounterCreate

//#endregion // End SERVICES Module

// ============================== ** Module ================================
// =============================**************===============================

//#region // Start Categories Module
export const ALL_CATEGORIES = `${LANG}${ApiCategory}/category_list/`; // All CategoryList
export const CATEGORY_CREATE = `${LANG}${ApiCategory}/category_create/`; // CategoryCreate
export const CHANGE_CATEGORY_STATUS = `${LANG}${ApiCategory}/change_category_status/`; // CategoryChangeStatus
export const CATEGORY_TEMP_DELETE = `${LANG}${ApiCategory}/category_temp_delete/`; // CategoryTempDelete Del
export const CATEGORIES_DELETED_LIST = `${LANG}${ApiCategory}/deleted_category_list/`; // DeletedCategory
export const CATEGORY_RESTORE = `${LANG}${ApiCategory}/category_restore/`; // CategoryRestore
export const CATEGORY_PARENT_DIALOG = `${LANG}${ApiCategory}/parent_dialog/`; // CategoryparentDialog
export const CATEGORY_DELETE_PERMANENTLY = `${LANG}${ApiCategory}/category_delete/`; // CategoryDelete
export const CATEGORY_RETRIEVE = `${LANG}${ApiCategory}/category_retrieve/`; // CategoryRetrieve
export const CATEGORY_UPDATE = `${LANG}${ApiCategory}/category_update/`; // CategoryUpdate
export const CATEGORY_IMAGE_DELETE = `${LANG}${ApiCategory}/category_image_delete/`; // CategoryImageDelete

//#endregion // End categories Module
// ============================== ** Module ================================
// =============================**************===============================

//#region // Start PRODUCTS Module
export const PRODUCT_CREATE = `${LANG}${ApiProducts}/product_create/`; // ProductCreate
export const CATEGORY_DIALOG = `${LANG}${ApiCategory}/category_dialog/`; // CategoryDialog
export const ALL_PRODUCTS = `${LANG}${ApiProducts}/product_list/`; // ProductList
export const PRODUCTS_CHANGE_STATUS = `${LANG}${ApiProducts}/product_change_status/`; // ProductChangeStatus
export const PRODUCTS_TEMP_DELETE = `${LANG}${ApiProducts}/product_temp_delete/`; // ProductTempDelete
export const PRODUCT_UPDATE = `${LANG}${ApiProducts}/product_update/`; // ProductUpdate
export const PRODUCT_RETRIEVE = `${LANG}${ApiProducts}/product_retrieve/`; // ProductRetrieve
export const PRODUCT_DELETED_LIST = `${LANG}${ApiProducts}/deleted_product_list/`; // DeletedProducts
export const PRODUCT_DELETE_PERMANENTLY = `${LANG}${ApiProducts}/product_delete/`; // ProductDelete
export const PRODUCT_RESTORE = `${LANG}${ApiProducts}/product_restore/`; // ProductRestore
export const PRODUCTS_ASSIGN_TO_CATEGORIES = `${LANG}${ApiProducts}/product_add_to_category/`; // AssignMultiProductsToMultiCategory
export const PRODUCTS_IMAGE_DELETE = `${LANG}${ApiProducts}/product_image_delete/`; // ProductImageDelete
export const PRODUCTS_UPDATE_MULTI_CATEGORY = `${LANG}${ApiProducts}/product_update_category/`; // UpdateMultiProductToMultiCategory

//#endregion // End PRODUCTS Module

// ============================== projects Module ================================
// =============================**************===============================
//#region // Start PROJECTS Module
export const ALL_PROJECTS = `${LANG}${ApiProject}/project_list/`; // ALL_ProjectList GET
export const PROJECT_CREATE = `${LANG}${ApiProject}/project_create/`; // ProjectCreate
export const PROJECT_TEMP_DELETE = `${LANG}${ApiProject}/project_temp_delete/`; // /ProjectTempDelete
export const CHANGE_PROJECT_STATUS = `${LANG}${ApiProject}/change_project_status/`; // ProjectChangeStatus
export const PROJECTS_DELETED_LIST = `${LANG}${ApiProject}/project_deleted_list/`; // DeletedProjects
export const PROJECT_RESTORE = `${LANG}${ApiProject}/project_restore/`; // ProjectRestore/
export const PROJECT_RETRIEVE = `${LANG}${ApiProject}/project_retrieve/`; // ProjectRetrieve
export const PROJECT_UPDATE = `${LANG}${ApiProject}/project_update/`; // ProjectUpdate
export const PROJECT_DELETE_PERMANENTLY = `${LANG}${ApiProject}/project_delete/`; // ProjectDelete
export const PROJECTS_DIALOG = `${LANG}${ApiProject}/Project_dialog/`; // ProjectDialog
export const PROJECT_IMAGE_DELETE = `${LANG}${ApiProject}/project_image_delete/`; // ProjectImageDelete

//#endregion // End PROJECTS Module

// ============================== About Us Module ================================
// =============================**************===============================
//#region // Start PROJECTS Module
export const ABOUT_US_CREATE = `${LANG}${ApiAboutUs}/aboutUs_create/`; // AboutUsCreate
export const ABOUT_US_UPDATE = `${LANG}${ApiAboutUs}/aboutUs_update/`; // AboutUsUpdate
export const ABOUT_US_LIST = `${LANG}${ApiAboutUs}/aboutUs_list/`; // AboutUsList
export const ABOUT_RETRIEVE = `${LANG}${ApiAboutUs}/aboutUs_retrieve/`; // AboutUsRetrieve

//#endregion // End PROJECTS Module

// ============================== ** Partner Module  ================================
// =============================**************===============================
//#region // Start PROJECTS Module
export const ALL_PARTNERS = `${LANG}${ApiPartner}/partner_list/`; // ALL PartnerList GET
export const PARTNER_CREATE = `${LANG}${ApiPartner}/partner_create/`; // PartnerCreate
export const PARTNER_TEMP_DELETE = `${LANG}${ApiPartner}/partner_temp_delete/`; // /PartnerTempDelete
export const CHANGE_PARTNER_STATUS = `${LANG}${ApiPartner}/partner_change_status/`; // PartnerChangeStatus
export const PARTNERS_DELETED_LIST = `${LANG}${ApiPartner}/partner_deleted_list/`; // Deleted Partner List
export const PARTNER_RESTORE = `${LANG}${ApiPartner}/partner_restore/`; // PartnerRestore
export const PARTNER_RETRIEVE = `${LANG}${ApiPartner}/partner_retrieve/`; // Partner Retrieve
export const PARTNER_UPDATE = `${LANG}${ApiPartner}/partner_update/`; // PartnerUpdate
export const PARTNER_DELETE_PERMANENTLY = `${LANG}${ApiPartner}/partner_delete/`; // PartnerDelete
export const PARTNER_DIALOG = `${LANG}${ApiPartner}/partner_dialog/`; // PartnerDialog

//#endregion // End PROJECTS Module

// ============================== ** Partner Module  ================================
// =============================**************===============================
//#region // Start PROJECTS Module
export const ALL_MESSAGES = `${LANG}${ApiContactUs}/contact_us_list/`; // ALL contact_us_list/  Messages
export const ALL_CHANGE_READ = `${LANG}${ApiContactUs}/contact_us_change_read/`; // ContactUsChangeRead
export const MESSAGE_DELETE_PERMANENTLY = `${LANG}${ApiContactUs}/contact_us_delete/`; // ContactUsDelete

// ============================== ** Partner Module  ================================
// =============================**************===============================
//#region // Start PDF File Module
export const UPLOAD_PDF = `${LANG}${ApiAboutUs}/upload_pdf_file/`; // UploadFile
export const DOWNLOAD_PDF = `${LANG}${ApiAboutUs}/download_file/`; // DownloadPdfFile

//#endregion // End PDF File Module

// ============================== About Us Module ================================
// =============================**************===============================
