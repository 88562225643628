import { createStore } from "vuex";
import UserLogin from "./user-login.js";
import lang from "./lang.js";
import colors from "./colors.js";
import theme from "./theme.js";
import UserProfile from "./user-profile.js";

export default createStore({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        UserLogin,
        lang,
        colors,
        theme,
        UserProfile,
    },
});
