<template>
    <!-- :color="theme == 'myCustomLightTheme' ? 'secondary' : ''" -->
    <v-navigation-drawer
        permanent
        app
        width="220"
        class="pt-0 px-0"
        :location="appLang == 'ar' ? 'right' : 'left'"
        elevation="2"
        :theme="theme"
        :color="'primary'"
        expand-on-hover
        rail
    >
        <v-list>
            <v-list-item
                :title="$t('projectName')"
                :subtitle="$t('AdminDashBoard')"
                :prepend-avatar="
                    require('@/assets/imgs/logo/f-gardens-logo.png')
                "
            >
                <template v-slot:prepend>
                    <v-avatar color="white">
                        <img
                            src="@/assets/imgs/logo/f-gardens-logo.png"
                            alt="Avatar"
                            style="width: 90px; height: 90px"
                        />
                    </v-avatar>
                </template>
            </v-list-item>
            <v-divider class=""></v-divider>
            <!-- users Start -->
            <v-list-item link to="/all-users" prepend-icon="mdi-account">
                <v-list-item-title>
                    {{ $t("users.usersTitle") }}
                    <v-tooltip activator="parent" location="bottom">
                        {{ $t("users.usersTitle") }}
                        {{ $t("table") }}
                    </v-tooltip>
                </v-list-item-title>
            </v-list-item>
            <!-- users End -->

            <!-- Services Start -->
            <v-list-item link to="/all-services" prepend-icon="mdi-face-agent">
                <v-list-item-title>
                    {{ $t("services.servicesTitle") }}
                    <v-tooltip activator="parent" location="bottom">
                        {{ $t("services.servicesTitle") }}
                    </v-tooltip>
                </v-list-item-title>
            </v-list-item>
            <!-- Services End -->

            <!-- Categories Start -->
            <v-list-item
                link
                to="/all-categories"
                prepend-icon="mdi-shape-plus"
            >
                <v-list-item-title>
                    <!-- {{ $t("services.servicesTitle") }} -->
                    categories
                    <v-tooltip activator="parent" location="bottom">
                        {{ $t("services.servicesTitle") }}
                    </v-tooltip>
                </v-list-item-title>
            </v-list-item>
            <!-- Categories End -->

            <!-- Products Start -->
            <v-list-item
                link
                to="/all-products"
                prepend-icon="mdi-forest-outline"
            >
                <v-list-item-title>
                    <!-- {{ $t("services.servicesTitle") }} -->
                    {{ $t("products.products") }}
                    <v-tooltip activator="parent" location="bottom">
                        {{ $t("products.products") }}
                    </v-tooltip>
                </v-list-item-title>
            </v-list-item>
            <!-- products End -->

            <!-- Products Start -->
            <v-list-item
                link
                to="/all-projects"
                prepend-icon="mdi-projector-screen"
            >
                <v-list-item-title>
                    <!-- {{ $t("services.servicesTitle") }} -->
                    {{ $t("projects.projects") }}
                    <v-tooltip activator="parent" location="bottom">
                        {{ $t("projects.projects") }}
                    </v-tooltip>
                </v-list-item-title>
            </v-list-item>
            <!-- products End -->

            <!-- partners Start -->
            <v-list-item link to="/all-partners" prepend-icon="mdi-handshake">
                <v-list-item-title>
                    <!-- {{ $t("services.servicesTitle") }} -->
                    {{ $t("partners.partners") }}
                    <v-tooltip activator="parent" location="bottom">
                        {{ $t("partners.partners") }}
                    </v-tooltip>
                </v-list-item-title>
            </v-list-item>
            <!-- partners End -->

            <!-- partners Start -->
            <v-list-item
                link
                to="/all-messages"
                prepend-icon="mdi-message-alert-outline"
            >
                <v-list-item-title>
                    <!-- {{ $t("services.servicesTitle") }} -->
                    {{ $t("messages.messages") }}
                    <v-tooltip activator="parent" location="bottom">
                        {{ $t("messages.messages") }}
                    </v-tooltip>
                </v-list-item-title>
            </v-list-item>
            <!-- partners End -->

            <!-- users group start -->
            <!-- <v-list class="border-dark-subtle p-0 border-2 rounded-1">
                <v-list-group
                    v-if="canShow"
                    v-model="groupOpen"
                    @mouseover="groupOpen = true"
                    @mouseleave="groupOpen = false"
                    active-color="white"
                >
                    <template v-slot:activator="{ props }">
                        <v-list-item prepend-icon="mdi-account" v-bind="props">
                            <v-list-title>
                                {{ $t("users.usersTitle") }}
                            </v-list-title>
                        </v-list-item>
                    </template>

                  
                    <v-list-item link to="/all-users">
                        <v-list-item-title>
                            <v-icon>mdi-account</v-icon>
                            {{ $t("users.usersTitle") }}
                            <v-tooltip activator="parent" location="bottom">
                                {{ $t("users.usersTitle") }}
                                {{ $t("table") }}
                            </v-tooltip>
                        </v-list-item-title>
                    </v-list-item>
                </v-list-group>
            </v-list> -->
            <!-- users group end -->
        </v-list>
    </v-navigation-drawer>
</template>
<script>
import store from "@/store";
// import { ref, watch } from "vue";
export default {
    name: "AppAside",
    props: {
        appLang: {
            type: String,
        },
    },
    setup() {
        return {};
    },
    data() {
        return {
            theme: store.getters.getAppTheme,
            userType: store.getters.getUserType,
            groupOpen: false,
        };
    },
    computed: {
        canShow() {
            const allowedUserTypes = ["ADMIN", "OWNER", "SUPERUSER"];
            return allowedUserTypes.includes(this.userType);
        },
    },
    watch: {
        "$store.getters.getAppTheme"(newTheme) {
            this.theme = newTheme;
            // console.log(newTheme, "from this :", this.theme);
        },
    },
    methods: {
        getDrawerColor(theme) {
            switch (theme) {
                case "myCustomLightTheme":
                    return "primary";
                case "light":
                    return "primary";
                case "dark":
                    return "black";
                case "customDarkTheme":
                    return "#15202b"; // or any color you want for customDarkTheme
                default:
                    return "#15202b";
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.all-users-link,
.add-users-link {
    padding: 5px;

    text-decoration: none;
    color: white;
    &:hover {
        background-color: rgba(204, 204, 204, 0.37);
    }
    &.router-link-exact-active {
        color: #42b983;
        text-decoration: underline;
        cursor: pointer;
    }
}
.add-users-link {
    font-size: 10pt;
}
</style>
