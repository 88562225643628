<template>
    <v-menu v-model="menu" :close-on-content-click="false" location="end">
        <template v-slot:activator="{ props }">
            <v-btn icon color="grey-darken-1" v-bind="props">
                <v-icon> mdi-tab-plus </v-icon>

                <v-tooltip activator="parent" location="bottom">
                    {{ $t("add") }}
                </v-tooltip>
            </v-btn>
        </template>

        <v-card min-width="100%" elevation="10">
            <v-list width="auto">
                <v-list-item
                    :prepend-avatar="
                        require('../../../assets/imgs/logo/f-gardens-logo-1.png')
                    "
                    :subtitle="$t('add')"
                    :title="$t('quickLinks')"
                >
                </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-list
                class="d-flex flex-row justify-content-around flex-wrap rounded-2 w-auto"
                row
            >
                <v-list-item
                    class="d-inline-block text-center mx-2 rounded-3"
                    active-class="text-white bg-grey-darken-1 "
                    v-for="link in addLinks"
                    :key="link"
                    width="auto"
                    :prepend-icon="link.icon"
                    :to="link.to"
                    >{{ link.title }}
                </v-list-item>
            </v-list>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn variant="text" @click="menu = false">
                    {{ $t("cancel") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>
<script>
export default {
    name: "AddListComponent",
    props: {
        // showAddList: {
        //     type: Boolean,
        // },
    },

    data() {
        return {
            fav: true,
            menu: false,
            message: false,
            hints: true,
            addLinks: [
                {
                    title: this.$t("user"),
                    to: "/create-user",
                    icon: " mdi-account-plus",
                },
                {
                    title: this.$t("services.servicesTitle"),
                    to: "/create-service",
                    icon: "mdi-face-agent",
                },
                {
                    title: this.$t("category"),
                    to: "/create-category",
                    icon: "mdi-shape-plus",
                },
                {
                    title: this.$t("products.product"),
                    to: "/create-product",
                    icon: "mdi-forest-outline",
                },
                {
                    title: this.$t("projects.project"),
                    to: "/create-project",
                    icon: "mdi-projector-screen-outline",
                },
                {
                    title: this.$t("partners.partner"),
                    to: "/create-partner",
                    icon: "mdi-handshake",
                },
                {
                    title: this.$t("role"),
                    to: "/new-permission-group",
                    icon: "mdi-shield-account-outline",
                },
            ],
        };
    },

    mounted() {},

    methods: {},
};
</script>
<style lang="scss"></style>
