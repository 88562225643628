import { backMethod } from "@/utils/request-function";
import { GET_USER_ME } from "@/utils/api";
import store from ".";
const state = {
    userData: {},
};
const mutations = {
    SET_USER_DATA(state, data) {
        state.userData = data;
    },
};

const actions = {
    async getMyProfile({ commit }) {
        if (store.getters.getIsLogin)
            try {
                const response = await backMethod("get", GET_USER_ME);
                // this.isLoading = false;
                const profileData = response.data;
                commit("SET_USER_DATA", profileData);
                // const unwrapped = JSON.parse(JSON.stringify(this.profileData));
                // console.log("my profile", unwrapped);
                // console.log("my profile", response.data);
            } catch (error) {
                if (error) {
                    console.error("Failed to save profileData:", error);
                }
                // this.isLoading = false;
            }
    },
};
const getters = {
    getUserData: (state) => state.userData,
};

export default {
    state,
    mutations,
    actions,
    getters,
};
