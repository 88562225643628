// import axios from "axios";
// import { BASE_URL } from "./constants";
// // import { languageToken } from "./constants";
// import store from "@/store";

// export const backMethod = async function (
//     method,
//     api,
//     data,
//     paramKey,
//     paramVal
// ) {
//     try {
//         // Check if paramKey is an array and has the same length as paramVal
//         if (
//             !Array.isArray(paramKey) ||
//             !Array.isArray(paramVal) ||
//             paramKey.length !== paramVal.length
//         ) {
//             throw new Error("Invalid parameters for URL construction.");
//         }

//         // Check if paramKey and paramVal are defined and non-null
//         if (!paramKey || !paramVal) {
//             throw new Error("Invalid parameters for URL construction.");
//         }

//         const params = Object.fromEntries(
//             paramKey.map((key, index) => [key, paramVal[index]])
//         );

//         const response = await axios({
//             method: method,
//             url: `${BASE_URL}${api}`,
//             data: data,
//             headers: {
//                 Authorization: `Bearer  ${store.getters.getUserToken}`,
//             },
//             params: params,
//         });
//         return response;
//     } catch (error) {
//         if (error.response) {
//             console.error(error.response.data);
//         }
//     }
// };
// ======================== old one ===============
import axios from "axios";
import { BASE_URL } from "./constants";
// import { languageToken } from "./constants";
import store from "@/store";

export const backMethod = async function (method, api, data, params) {
    try {
        const response = await axios({
            method: method,
            url: `${BASE_URL}${api}`,
            data: data,
            headers: {
                Authorization: `Bearer  ${store.getters.getUserToken}`,
            },
            params: params,
        });
        return response;
    } catch (error) {
        // if (error) {
        //     console.error(error.response);
        //     console.log(error.response.data);
        // } else {
        //     console.error(error);
        // }
        return error;
    }
};
