const state = {
    theme: localStorage.getItem("theme") || "light",
    themesList: JSON.parse(localStorage.getItem("themesList")) || [],
};
const mutations = {
    SET_THEME(state, theme) {
        state.theme = theme;
        localStorage.setItem("theme", theme);
    },
    SET_THEME_LIST(state, themes) {
        state.themesList = themes;
        localStorage.setItem("themesList", JSON.stringify(themes));
    },
};

const actions = {
    setThemes({ commit }, themes) {
        commit("SET_THEME_LIST", themes);
    },
    getDrawerColor({ state }) {
        switch (state.theme) {
            case "myCustomLightTheme":
            case "light":
                return "primary";
            case "dark":
                return "black";
            case "customDarkTheme":
                return "#15202b";
            default:
                return "primary";
        }
    },
};
const getters = {
    getAppTheme: (state) => state.theme,
    getAppThemesList: (state) => state.themesList,
};

export default {
    state,
    mutations,
    actions,
    getters,
};
