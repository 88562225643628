<template>
    <div>
        <vContainer class="py-0 z-1">
            <!-- <VForm class="bg-primary pa-5 ma-3" v-if="showForm">
                <v-btn
                    @click="showForm = false"
                    small
                    elevation="3"
                    color="danger"
                    prepend-icon="mdi-close"
                >
                    close</v-btn
                >
                <VRow class="my-2">
                    <VCol cols="12" md="6" lg="6" sm="6">
                        <v-text-field
                            name="name"
                            label="title"
                            prepend-icon="mdi-text"
                        ></v-text-field>
                    </VCol>
                    <VCol cols="12" md="6" lg="6" sm="6">
                        <v-text-field
                            name="name"
                            label="description"
                            prepend-icon="mdi-file"
                        ></v-text-field>
                    </VCol>
                </VRow>
                <VRow class="my-2" justify="center">
                    <v-btn small elevation="5" color="success"> update</v-btn>
                </VRow>
            </VForm>
            <hr /> -->
            <v-btn
                @click="editSection(tData.id)"
                color="warning"
                class="ma-10 mx-auto position-fixed"
                size="large"
                icon
                style="z-index: 9"
            >
                {{ $t("edit") }}
            </v-btn>
            <v-timeline align="start">
                <v-timeline-item
                    v-for="(item, i) in items"
                    :key="i"
                    :dot-color="item.color"
                    :icon="item.icon"
                    fill-dot
                >
                    <v-card>
                        <v-card-title :class="['text-h6', `bg-${item.color}`]">
                            {{ item.title }}
                        </v-card-title>
                        <!-- :class="[`text-${item.color}`]" -->
                        <v-card-text class="bg-white text--primary pa-5">
                            {{ getTDataProperty(item.title) }}
                        </v-card-text>
                    </v-card>
                </v-timeline-item>
            </v-timeline>
        </vContainer>
    </div>
</template>
<script>
import { backMethod } from "@/utils/request-function";
import { ABOUT_RETRIEVE } from "@/utils/api";
// ABOUT_US_UPDATE
export default {
    name: "HomeView",
    data: () => ({
        lang: localStorage.lang,
        items: [
            {
                color: "primary",
                icon: "mdi-account",
                title: "Who We Are",
                descriptionWhoWe: "",
                descriptionWhoWeAr: "",
            },
            {
                color: "red-lighten-2",
                icon: "mdi-star",
                title: "Our vision",
                descriptionVision: "",
                descriptionVisionAr: "",
            },
            {
                color: "purple-lighten-2",
                icon: "mdi-book-variant",
                title: "Our Mission",
                descriptionMission: "",
                descriptionMissionAr: "",
            },
            {
                color: "green-lighten-1",
                icon: "mdi-airballoon",
                title: "Our Promise ",
                descriptionPromise: "",
                descriptionPromiseAr: "",
            },
        ],
        tData: {
            who_we_are: "",
            who_we_are_ar: "",

            our_vision: "",
            our_vision_ar: "",

            our_mission: "",
            our_mission_ar: "",

            our_promise: "",
            our_promise_ar: "",
        },
        showForm: false,
    }),
    methods: {
        // getTDataProperty(title) {
        //     // Normalize title to match tData keys
        //     const normalizedTitle = title
        //         .trim()
        //         .toLowerCase()
        //         .replace(/\s+/g, "_");

        //     // Check if the property exists in tData
        //     if (
        //         Object.prototype.hasOwnProperty.call(
        //             this.tData,
        //             normalizedTitle
        //         )
        //     ) {
        //         return this.tData[normalizedTitle];
        //     } else {
        //         return ""; // Or any default value you want
        //     }
        // },
        getTDataProperty(title) {
            // Normalize title to match tData keys
            const normalizedTitle = title
                .trim()
                .toLowerCase()
                .replace(/\s+/g, "_");

            // Check if the property exists in tData
            if (
                this.lang === "ar" &&
                Object.prototype.hasOwnProperty.call(
                    this.tData,
                    normalizedTitle + "_ar"
                )
            ) {
                return this.tData[normalizedTitle + "_ar"];
            } else if (
                Object.prototype.hasOwnProperty.call(
                    this.tData,
                    normalizedTitle
                )
            ) {
                return this.tData[normalizedTitle];
            } else {
                return ""; // Or any default value you want
            }
        },
        async getAboutUs() {
            this.isLoading = true;
            try {
                const response = await backMethod("get", ABOUT_RETRIEVE, null, {
                    // aboutUs_id: "233f6ba7-950e-4858-b661-1bd9af3a51f7",
                    aboutUs_id: "b98b4a92-0f4c-47c6-87dc-03d39a8f3ef4",
                });
                if (response.status === 200) {
                    this.isLoading = false;

                    this.tData = response.data;
                    this.items[0].descriptionWhoWe = this.tData.who_we_are;
                    this.items[0].descriptionWhoWeAr = this.tData.who_we_are_ar;
                    // our vision
                    this.items[1].descriptionVision = this.tData.our_vision;
                    this.items[1].descriptionVisionAr =
                        this.tData.our_vision_ar;
                    // our vision
                    this.items[2].descriptionMission = this.tData.our_mission;
                    this.items[2].descriptionMissionAr =
                        this.tData.our_mission_ar;
                } else {
                    this.isLoading = false;
                }
            } catch (error) {
                console.error("Failed to get all users:", error);
                this.isLoading = false;
            }
        },

        // ==========
        editSection(id) {
            // console.log(this.formData);
            // this.showForm = true;
            this.$router.push(`/about-us-edit/${id}`);
        },
    },
    async mounted() {
        await this.getAboutUs();
    },
};
</script>

<style lang="scss">
.home {
    min-height: var(--min-height-page);
    min-height: 300vh;
    width: 100%;
}
</style>
<script setup></script>
