const state = {
    defaultColor:
        localStorage.getItem("color") ||
        "var(--aside-bg-color-blue)" ||
        "#28779B",
    selectedColor: localStorage.getItem("color") || "#28779B",
};
const mutations = {
    SET_DEFAULT_COLOR(state, color) {
        state.defaultColor = color;
    },
    SET_SELECTED_COLOR(state, color) {
        state.selectedColor = color;
    },
};

const actions = {};
const getters = {
    getDefaultColor: (state) => state.defaultColor,
    getSelectedColor: (state) => state.selectedColor,
};

export default {
    state,
    mutations,
    actions,
    getters,
};
