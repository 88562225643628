import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import store from "@/store";

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
    },
    {
        path: "/my-profile",
        name: "UserProfile",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/views/users/UserProfile.vue"
            ),
        beforeEnter: (to, from, next) => {
            // ...
            if (store.getters.getIsLogin) {
                next();
            } else {
                next("/");
            }
        },
    },
    {
        path: "/all-users",
        name: "UsersTable",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/views/users/UsersTable.vue"
            ),
        // beforeEnter: (to, from, next) => {
        //     // ...
        //     if (
        //         store.getters.getUserType === "OWNER" ||
        //         store.getters.getUserType === "SUPERUSER"
        //     ) {
        //         next();
        //     } else {
        //         next("/");
        //     }
        // },
    },
    {
        path: "/test",
        name: "TestPage",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "@/views/TestPage.vue"),
    },

    {
        path: "/about-us-edit/:id",
        name: "AboutUsEdit",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/views/about-us/AboutUsEdit.vue"
            ),
    },
    {
        path: "/setting",
        name: "ThemeSettings",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "@/views/ThemeSetting.vue"),
        beforeEnter: (to, from, next) => {
            // ...
            if (
                store.getters.getIsLogin
                // store.getters.getUserType === "SUPERUSER"
            ) {
                next();
            } else {
                next("/notfound");
            }
        },
    },
    {
        path: "/create-user",
        name: "CreateUser",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/views/users/CreateUser.vue"
            ),
        // beforeEnter: (to, from, next) => {
        //     // ...
        //     if (
        //         store.getters.getUserType === "OWNER" ||
        //         store.getters.getUserType === "SUPERUSER"
        //     ) {
        //         next();
        //     } else {
        //         next("/notfound");
        //     }
        // },
    },
    {
        path: "/users-deleted-list",
        name: "UsersDeletedList",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/views/users/UsersDeletedList.vue"
            ),
        // beforeEnter: (to, from, next) => {
        //     // ...
        //     if (
        //         store.getters.getUserType === "OWNER" ||
        //         store.getters.getUserType === "SUPERUSER"
        //     ) {
        //         next();
        //     } else {
        //         next("/notfound");
        //     }
        // },
    },
    {
        path: "/update-user-data/:id",
        name: "EditUserByAdmin",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/views/users/EditUserByAdmin.vue"
            ),
        // beforeEnter: (to, from, next) => {
        //     // ...
        //     if (
        //         store.getters.getUserType === "OWNER" ||
        //         store.getters.getUserType === "SUPERUSER"
        //     ) {
        //         next();
        //     } else {
        //         next("/notfound");
        //     }
        // },
    },
    // groups and permissions Start

    // group list
    {
        path: "/group-list",
        name: "GroupList",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/views/permissions-groups/GroupList.vue"
            ),
        // beforeEnter: (to, from, next) => {
        //     // ...
        //     if (
        //         store.getters.getUserType === "OWNER" ||
        //         store.getters.getUserType === "SUPERUSER"
        //     ) {
        //         next();
        //     } else {
        //         next("/notfound");
        //     }
        // },
    },
    // group edit page
    {
        path: "/group-list/:id",
        name: "EditGroup",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/views/permissions-groups/EditGroup.vue"
            ),
        // beforeEnter: (to, from, next) => {
        //     // ...
        //     if (
        //         store.getters.getUserType === "OWNER" ||
        //         store.getters.getUserType === "SUPERUSER"
        //     ) {
        //         next();
        //     } else {
        //         next("/notfound");
        //     }
        // },
    },
    {
        path: "/new-permission-group",
        name: "NewPermissionGroup",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/views/permissions-groups/NewPermissionGroup.vue"
            ),
        // beforeEnter: (to, from, next) => {
        //     // ...
        //     if (
        //         store.getters.getUserType === "OWNER" ||
        //         store.getters.getUserType === "SUPERUSER"
        //     ) {
        //         next();
        //     } else {
        //         next("/notfound");
        //     }
        // },
    },
    // groups and permissions End

    // services model
    {
        path: "/all-services",
        name: "ServicesTable",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/views/services/ServicesTable.vue"
            ),
        // beforeEnter: (to, from, next) => {
        //     // ...
        //     if (
        //         store.getters.getUserType === "OWNER" ||
        //         store.getters.getUserType === "SUPERUSER"
        //     ) {
        //         next();
        //     } else {
        //         next("/");
        //     }
        // },
    },
    {
        path: "/services-deleted-list",
        name: "ServicesDeletedTable",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/views/services/ServicesDeletedTable.vue"
            ),
        // beforeEnter: (to, from, next) => {
        //     // ...
        //     if (
        //         store.getters.getUserType === "OWNER" ||
        //         store.getters.getUserType === "SUPERUSER"
        //     ) {
        //         next();
        //     } else {
        //         next("/");
        //     }
        // },
    },
    {
        path: "/create-service",
        name: "CreateService",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/views/services/CreateService.vue"
            ),
        // beforeEnter: (to, from, next) => {
        //     // ...
        //     if (
        //         store.getters.getUserType === "OWNER" ||
        //         store.getters.getUserType === "SUPERUSER"
        //     ) {
        //         next();
        //     } else {
        //         next("/notfound");
        //     }
        // },
    },
    {
        path: "/update-services/:id",
        name: "EditService",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/views/services/EditService.vue"
            ),
    },

    // categories Start
    {
        path: "/all-categories",
        name: "CategoriesTable",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/views/categories/CategoriesTable.vue"
            ),
    },
    {
        path: "/categories-deleted-list",
        name: "CategoriesDeletedTable",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/views/categories/CategoriesDeletedTable.vue"
            ),
    },
    {
        path: "/create-category",
        name: "CreateCategory",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/views/categories/CreateCategory.vue"
            ),
    },
    {
        path: "/update-categories/:id",
        name: "EditCategory",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/views/categories/EditCategory.vue"
            ),
    },
    {
        path: "/products-deleted-list",
        name: "ProductsDeletedTable",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/views/products/ProductsDeletedTable.vue"
            ),
    },

    // categories End

    // products Start
    {
        path: "/create-product",
        name: "CreateProduct",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/views/products/CreateProduct.vue"
            ),
    },
    {
        path: "/all-products",
        name: "ProductsTable",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/views/products/ProductsTable.vue"
            ),
    },
    {
        path: "/update-product/:id",
        name: "EditProduct",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/views/products/EditProduct.vue"
            ),
    },

    // products End

    // products Start
    {
        path: "/create-project",
        name: "CreateProject",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/views/projects/CreateProject.vue"
            ),
    },
    {
        path: "/all-projects",
        name: "ProjectsTable",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/views/projects/ProjectsTable.vue"
            ),
    },
    {
        path: "/update-project/:id",
        name: "EditProject",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/views/projects/EditProject.vue"
            ),
    },
    {
        path: "/projects-deleted-list",
        name: "ProjectsDeletedTable",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/views/projects/ProjectsDeletedTable.vue"
            ),
    },
    // Projects End

    // Partners Start
    {
        path: "/create-partner",
        name: "CreatePartner",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/views/partners/CreatePartner.vue"
            ),
    },
    {
        path: "/all-partners",
        name: "PartnersTable",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/views/partners/PartnersTable.vue"
            ),
    },
    {
        path: "/update-partner/:id",
        name: "EditPartner",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/views/partners/EditPartner.vue"
            ),
    },
    {
        path: "/partners-deleted-list",
        name: "PartnersDeletedTable",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/views/partners/PartnersDeletedTable.vue"
            ),
    },
    // Partners End

    // messages Start
    {
        path: "/all-messages",
        name: "MessagesTable",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/views/messages/MessagesTable.vue"
            ),
    },
    {
        path: "/update-partner/:id",
        name: "EditPartner",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/views/partners/EditPartner.vue"
            ),
    },
    {
        path: "/partners-deleted-list",
        name: "PartnersDeletedTable",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/views/partners/PartnersDeletedTable.vue"
            ),
    },
    // messages End

    {
        path: "/:pathMatch(.*)*", // Catch all unmatched routes
        name: "NotFound",
        component: () =>
            import(/* webpackChunkName: "about" */ "@/views/NotFound"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

const beforeEnterAll = (to, from, next) => {
    // Check if the route requires authentication
    if (to.meta.requiresAuth) {
        // Implement your login check logic here
        const isLoggedIn = localStorage.isLogin === "true";

        if (!isLoggedIn) {
            // If not logged in, redirect to login or any other route
            next("/");
        } else {
            // If logged in, proceed to the requested route
            next();
        }
    } else {
        // If the route doesn't require authentication, proceed to the route
        next();
    }
};

// Apply beforeEnterAll to all routes
router.beforeEach(beforeEnterAll);

export default router;
