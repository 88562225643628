<template>
    <v-row justify="center" v-show="showDialog === true">
        <v-dialog
            v-model="dialog"
            persistent
            col="12"
            class="col-xs-9 col-sm-10 col-md-6 col-lg-4 col-xl-4 col-xxl-4"
        >
            <!-- <template v-slot:activator="{ props }">
                <v-btn color="primary" v-bind="props"> Open Dialog </v-btn>
            </template> -->
            <v-card>
                <v-card-title
                    class="text-h5 text-center bg-amber"
                    style="line-height: 1rem"
                >
                    <v-icon size="s">mdi mdi-alert-decagram-outline</v-icon>
                    <span class="fs-5 mx-2"> {{ $t("alert") }} </span>
                </v-card-title>
                <v-card-text class="text-center fs-4"
                    >{{ $t("areYouSure") }}
                </v-card-text>
                <v-card-actions width="auto" class="mx-auto my-1">
                    <v-btn
                        color="white"
                        class="bg-danger"
                        variant="text"
                        @click="closeDialog()"
                    >
                        {{ $t("cancel") }}
                    </v-btn>
                    <v-spacer class=".d-md-flex"></v-spacer>

                    <v-btn
                        width="100px"
                        color="white"
                        class="bg-success"
                        variant="text"
                        @click="logout()"
                    >
                        {{ $t("logout") }}
                    </v-btn>
                    <!-- permanently -->
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
export default {
    name: "LogoutDialog",
    props: {
        showDialog: {
            type: Boolean,
        },
    },
    data() {
        return {
            dialog: this.showDialog,
            done: false,
        };
    },

    mounted() {},
    methods: {
        logout() {
            this.$emit("logout");
            // console.log("out");
            this.closeDialog();
        },
        closeDialog() {
            this.$emit("closeDialog");
        },
    },
    watch: {
        showDialog(newValue) {
            this.dialog = newValue;
        },
    },
};
</script>
