<template>
    <!-- v-if="!this.$store.getters.getIsLogin" -->
    <!-- v-if="this.$store.getters.getIsLogin === false" -->
    <UserLoginComponent v-if="isLogin === false" transition="slide-fade" />
    <v-app transition="slide-fade" v-else>
        <!--  -->
        <v-layout
            @mouseenter.once="getMyData()"
            :style="appLang == 'ar' ? 'direction:rtl' : 'direction:ltr'"
            class="rounded rounded-md"
        >
            <AppAside
                class="position-fixed"
                v-if="mobileToggleAside"
                :appLang="appLang"
            />

            <SettingsSidebar :appLang="appLang" :drawer="drawer" />

            <AddHeader
                class="position-fixed"
                :style="appLang == 'ar' ? 'direction:rtl' : 'direction:ltr'"
                @toggleTheme="toggleTheme()"
                @changeLanguage="changeLanguage()"
                @toggleAsideMobile="toggleAsideMobile()"
                @openDialog="openDialog()"
                :drawer="drawer"
                @toggleSettingBar="toggleSettingBar()"
            />

            <v-main
                class="d-flex align-center justify-center"
                style="min-height: 300px"
            >
                <!-- <v-btn @click="openDialog()">open dialog</v-btn> -->
                <!-- <v-select
                    label="Select Theme"
                    v-model="selectedTheme"
                    :items="myThemes"
                    @update:modelValue="setTheme()"
                ></v-select> -->
                <transition name="slide" mode="in-out">
                    <router-view class="" />
                </transition>

                <!-- <button @click="changeLanguage()">changeLanguage</button> -->
            </v-main>

            <!-- <ConfirmDialog
                :showDialog="showDialog"
                @closeDialog="closeDialog"
                @logout="logout()"
            /> -->
            <LogoutDialog
                :showDialog="showDialog"
                @closeDialog="closeDialog"
                @logout="logout()"
            />
        </v-layout>
    </v-app>
</template>
<script>
export default {
    name: "Vue3UserApp",

    data() {
        return {};
    },
    computed: {
        isLogin() {
            return this.$store.getters.getIsLogin;
        },
    },
    mounted() {},

    methods: {},
};
</script>
<script setup>
import AppAside from "@/components/main-app-components/app-aside/AppAside.vue";
import SettingsSidebar from "@/components/main-app-components/app-aside/SettingsSidebar.vue";
import AddHeader from "@/components/main-app-components/app-header/AddHeader.vue";
import UserLoginComponent from "@/components/user-components/LoginComponent.vue";

// import ConfirmDialog from "@/components/global/ConfirmDialog.vue";
import LogoutDialog from "@/components/user-components/LogOutDialog.vue";

import { ref, onMounted, watch } from "vue";

import { useTheme } from "vuetify";
import store from "@/store";
import router from "./router";

// ====

//#region Vars
const lang = ref(store.getters.getAppLang);
const appLang = ref(lang.value);
const myTheme = ref(store.getters.getAppTheme);
const theme = useTheme();

const showDialog = ref(false);
const drawer = ref(false);

const mobileToggleAside = ref(true);

function getMyData() {
    store.dispatch("getMyProfile");
}

function toggleAsideMobile() {
    mobileToggleAside.value = !mobileToggleAside.value;
}

// const myThemes = store.getters.getAppThemesList;

// const selectedTheme = ref(store.getters.getAppTheme);

//#endregion

//#region Functions
function toggleTheme() {
    theme.global.name.value = theme.global.current.value.dark
        ? "light"
        : "dark";

    if (store.getters.getAppTheme == "light") {
        store.commit("SET_THEME", "dark");
    } else {
        store.commit("SET_THEME", "light");
    }

    // const val = theme.global.current.value;
    // console.log(`Current theme is dark? ${JSON.stringify(val)}`);
    // localStorage.setItem("val", JSON.stringify(val));
}

function toggleSettingBar() {
    drawer.value = !drawer.value;
}

const changeLanguage = () => {
    if (store.getters.getAppLang == "ar") {
        store.commit("SET_LANG", "en");
    } else {
        store.commit("SET_LANG", "ar");
    }
    // Your language change logic
};
const closeDialog = function () {
    showDialog.value = false;
};
const openDialog = function () {
    showDialog.value = true;
};
const logout = function () {
    router.push("/");
    store.dispatch("logout");
};

//#endregion

//#region Hocks
onMounted(async () => {
    // mobileToggleAside.value = true;
    // if (window.innerWidth < 600) {
    //     mobileToggleAside.value = false;
    // }
    // getMyData();
    checkMobileWidth();
    if (localStorage.getItem("isLogin")) {
        if (localStorage.getItem("isLogin")) {
            store.dispatch("getMyProfile");
        }
    }
    function checkMobileWidth() {
        mobileToggleAside.value = window.innerWidth > 600;
    }

    window.addEventListener("resize", checkMobileWidth);
    // if (localStorage.getItem("isLogin")) {
    // }
    localStorage.setItem("lang", store.getters.getAppLang);
    if (!localStorage.getItem("theme"))
        localStorage.setItem("theme", store.getters.getAppTheme);
});
watch(
    () => store.getters.getAppLang,
    (newLang) => {
        appLang.value = newLang;
    }
);
watch(
    () => store.getters.getAppTheme,
    (newTheme) => {
        myTheme.value = newTheme;
        theme.global.name.value = newTheme; // Assuming Vuetify uses this for theme switching
        localStorage.setItem("theme", newTheme); // Update local storage for theme
    }
);
//#endregion
</script>

<style lang="scss">
//#region Style
#app {
    position: relative;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Cairo", sans-serif;
    // text-align: center;
    // color: #2c3e50;
}

.v-theme--dark {
    color-scheme: #212529 !important;
}

.logo-container {
    width: 65px;
    height: 65px;
    & img {
        scale: 1.5;
    }
}
nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;
        text-decoration: none;

        &.router-link-exact-active {
            color: #42b983;
            text-decoration: underline;
        }
    }
}
.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    // transform: translateX(20px);
    opacity: 0;
}
//#endregion

.slide-enter-active {
    animation: transitionSliderIn 0.7s ease-in-out;
}
.slide-leave-active {
    animation: transitionSliderOut 0.7s ease-in-out;
}

@keyframes transitionSliderIn {
    from {
        opacity: 0;
        // transform: translate(-20%);
    }
    to {
        opacity: 1;
        // transform: translate(0%);
    }
}
@keyframes transitionSliderOut {
    from {
        opacity: 1;
        // transform: translate(0%);
    }
    to {
        opacity: 0;
        // transform: translate(-20%);
    }
}
</style>
