<template>
    <!-- <div>loading</div> -->
    <div
        :style="{ height: height }"
        v-if="isLoading"
        class="loading-page-container"
    >
        <h1 class="Loading-title">
            <img
                class="loading-img"
                src="../../assets/imgs/loading/q-loading-01.gif"
                alt="loading ..."
            />
        </h1>
    </div>
</template>
<script>
export default {
    name: "LoadingComponent",
    props: {
        isLoading: {
            type: Boolean,
        },
    },
    data() {
        return {};
    },

    mounted() {},

    methods: {},
};
</script>

<style lang="scss">
.loading-page-container {
    min-height: 100vh;
    // height: height;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.473);
    background-color: #ffffff;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    display: flex;
    align-items: center;
    justify-content: center;
}
.loading-img {
    // width: 100vw !important;
    // height: 100vh !important;
    // backdrop-filter: blur(31px);
    width: 200px !important;
    // width: 100% !important;
    height: 200px;
}
</style>
