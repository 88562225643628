<template>
    <v-app-bar
        density="comfortable"
        :order="order"
        flat
        elevation="1"
        class="app-main-nav-bar"
    >
        <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
        <!-- class="d-inline d-sm-none" -->
        <v-btn
            flat
            class="d-inline transparent-hover pa-0 ma-1"
            @click="toggleAsideMobile"
            icon=""
            width="25px"
        >
            <v-icon style="font-size: 12pt">mdi-dots-vertical</v-icon>
        </v-btn>
        <v-toolbar-title class="ms-0">
            <router-link
                class="mx-1 text-decoration-none text-amber-darken-2"
                color="grey-darken-2"
                to="/"
            >
                <span
                    class="d-none d-sm-flex text-success"
                    style="font-weight: 700"
                >
                    <v-img
                        class="d-inline-block mx-0 pa-0"
                        width="35"
                        :src="
                            require('@/assets/imgs/logo/f-gardens-logo-1.png')
                        "
                    ></v-img>
                    <span class="mx-2 mt-3">{{ $t("projectName") }}</span>
                </span>
            </router-link>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <template v-slot:append>
            <span>
                <!-- arabic ع -->
                <v-img
                    v-show="$store.getters.getAppLang == 'ar'"
                    @click="changeLanguage()"
                    class="rounded-circle"
                    style="cursor: pointer"
                    :width="30"
                    :height="30"
                    aspect-ratio="16/9"
                    cover
                    :src="require('@/assets/imgs/app-header/us_flag.jpg')"
                ></v-img>
                <v-tooltip activator="parent" location="bottom">
                    {{ $t("toolTips.changeLanguage") }}
                </v-tooltip>
            </span>
            <!--  English Icon -->
            <span style="width: auto">
                <v-icon
                    v-show="$store.getters.getAppLang == 'en'"
                    @click="changeLanguage()"
                    color="secondary"
                >
                    mdi-abjad-arabic
                </v-icon>
                <v-tooltip activator="parent" location="bottom">
                    {{ $t("toolTips.changeLanguage") }}
                </v-tooltip>
            </span>
            <!-- setting Start -->
            <span @click="toggleSettingBar()" class="mx-2">
                <v-icon>mdi-cog</v-icon>
                <v-tooltip activator="parent" location="bottom">
                    {{ $t("toolTips.settings") }}
                </v-tooltip>
            </span>
            <!-- setting End -->
            <span class="mx-2">
                <v-icon
                    v-if="darkLightThem == 'light' || darkLightThem == 'dark'"
                    @click="toggleTheme()"
                    >mdi-compare</v-icon
                >
                <v-tooltip activator="parent" location="bottom">
                    {{ $t("toolTips.changeTheme") }}
                </v-tooltip>
            </span>
        </template>
        <!-- <span
            @click="ToggleShowAddList()"
            style="cursor: pointer"
            class="mx-3 text-decoration-none"
        >
            <v-icon color="grey-darken-2">mdi-tab-plus</v-icon>
            <v-tooltip activator="parent" location="bottom">
                {{ $t("add") }}
            </v-tooltip>
        </span> -->
        <AddListComponent />
        <span @click="openDialog()" class="mx-3 text-decoration-none">
            <v-icon color="grey-darken-2">mdi-logout</v-icon>
            <v-tooltip activator="parent" location="bottom">
                {{ $t("logout") }}
            </v-tooltip>
        </span>
    </v-app-bar>
</template>
//
<script>
import store from "@/store";
import AddListComponent from "@/components/main-app-components/app-header/AddListComponent.vue";
// import router from "@/router";
export default {
    name: "AddHeader",
    components: {
        AddListComponent,
    },
    setup() {
        return {};
    },
    data() {
        return {
            darkLightThem: store.getters.getAppTheme,
            showSettings: false,
        };
    },

    mounted() {},
    props: {
        drawer: {
            type: Boolean,
        },
        ToggleShowAddList: {
            type: Function,
        },
    },

    methods: {
        toggleAsideMobile() {
            this.$emit("toggleAsideMobile");
        },
        toggleTheme() {
            this.$emit("toggleTheme");
        },
        changeLanguage() {
            this.$emit("changeLanguage");
        },
        openDialog() {
            this.$emit("openDialog");
        },
        toggleSettingBar() {
            this.$emit("toggleSettingBar");
        },
    },
    watch: {
        "$store.getters.getAppTheme"(newValue) {
            // console.log("darkLightThem changed to:", newValue);
            this.darkLightThem = newValue;
            // You can perform any actions here based on the new value
        },
    },
};
</script>

<style lang="scss">
.v-toolbar-title__placeholder {
    width: fit-content;
}
@media screen and (width: 900px) {
    .app-main-nav-bar {
        display: none !important;
    }
}
.setting-dialog {
    position: absolute;
    top: 0px;
    right: 43%;
    z-index: 999 !important;
    width: 200px;
    margin-top: 5px;
}
.transparent-hover:hover {
    background-color: transparent !important;
}
</style>
