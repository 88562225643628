import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from "axios";
import "normalize.css";

// ============================ ********************** =============================
// ============================  Libraries Configurations =============================
// ============================ ********************** =============================

// bootstrap Start
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

// bootstrap End

// ============================ ********************** =============================
// ============================ Next Library Configuration =============================
// ============================ ********************** =============================

// font awesome Start
import "../src/assets/fontawesome-free-6.5.1-web/css/all.css";
import "@fortawesome/fontawesome-free/css/all.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
    faCartShopping,
    faUserSecret,
} from "@fortawesome/free-solid-svg-icons";
library.add(faUserSecret, faCartShopping);
// const app = createApp(App);
// app.component("font-awesome-icon", FontAwesomeIcon);
// app.use(library);
// font awesome End

// ============================ ********************** =============================
// ============================ Next Library Configuration =============================
// ============================ ********************** =============================

// ======== jwt Start =====
// import jwt_decode from "jwt-decode";
// app.config.globalProperties.$jwt_decode = jwt_decode;
// import { jwtDecode } from "jwt-decode";
import * as jwtDecode from "jwt-decode";
// appA.config.globalProperties.$jwt_decode = jwtDecode;
// ======== jwt End =====

// ============ i18n Start;
import i18n from "@/i18n/i18n.js";
// ====== i18n end ======

// ============================ ********************** =============================
// ============================ Next Library Configuration =============================
// ============================ ********************** =============================

import vuetify from "@/plugins/vuetify";
// ====== Vuetify End======

// ============================ ********************** =============================
// ============================ Next Library Configuration =============================
// ============================ ********************** =============================

// ====== jwt-decode Start======

// ====== jwt-decode End======

// ============================ ********************** =============================
// ============================ Next Library Configuration =============================
// ============================ ********************** =============================

// import VeeValidate from 'vee-validate';
// import VeeValidate from "vee-validate";
import * as VeeValidate from "vee-validate";
import "./scss/main.scss";

const appA = createApp(App);
appA.config.globalProperties.$jwt_decode = jwtDecode;

// createApp(App)
appA.component("font-awesome-icon", FontAwesomeIcon)
    .use(store, axios)
    .use(router)
    .use(vuetify)
    .use(VeeValidate)
    .use(i18n)
    .use(library)
    // .use(Vuelidate)
    // .component("Vuelidate", VuelidatePlugin)
    .mount("#app");
