import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "vuetify/styles";
import "@mdi/font/css/materialdesignicons.css";
import store from "@/store";

// ======================  get themes list Start ================

// ==============================================
// ========================================== get themes list End ===========================
const customDarkTheme = {
    dark: true,
    colors: {
        background: "#15202b",
        surface: "#15202b",
        primary: "#FFD700",
        secondary: "#03dac6",
        error: "#ff5722",
    },
};
const myCustomLightTheme = {
    dark: false,
    colors: {
        background: "#FFFFFF",
        surface: "#FFFFFF",
        primary: "#FFD700",
        "primary-lighten-1": "#b593ff52",
        "primary-darken-1": "#3700B3",

        secondary: "#03DAC6",
        "secondary-darken-1": "#018786",
        error: "#B00020",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
    },
};
const light = {
    dark: false,
    colors: {
        background: "#FFFFFF",
        surface: "#FFFFFF",
        primary: "#00695c",
        "primary-lighten-1": "#FFD700",
        "primary-darken-1": "#FFD700",
        secondary: "#03DAC6",
        "secondary-darken-1": "#018786",
        error: "#B00020",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
    },
};

store.dispatch("setThemes", [
    "light",
    "dark",
    "customDarkTheme",
    "myCustomLightTheme",
]);

const vuetify = createVuetify({
    components,
    directives,
    ssr: true,
    theme: {
        defaultTheme: store.getters.getAppTheme,
        themes: {
            light: light,
            dark: {
                colors: {
                    primary: "#00695c",
                },
            },
            myCustomLightTheme,
            customDarkTheme,
            // selectedThemeByFun,
        },
        dark: false,
    },
    icons: {
        defaultSet: "mdi",
    },
});

export default vuetify;
