import { createI18n } from "vue-i18n";
import store from "@/store";

import en from "@/i18n/en.json";
import ar from "@/i18n/ar.json";

const i18n = createI18n({
    locale: store.getters.getAppLang || localStorage.getItem("lang"),
    messages: {
        en: en,
        ar: ar,
    },
});
store.watch(
    () => store.getters.getAppLang,
    (newLang) => {
        i18n.global.locale = newLang;
    }
);

export default i18n;
